import { colors, styled } from "@mui/material";

export const WorkspaceColumn = styled("div")(() => ({
  flex: 1,
  width: 235,
  display: "flex",
  flexDirection: "column",
}));

export const ComparisonDetailsBody = styled("div")(() => ({
  flex: 1,
  display: "flex",
  alignSelf: "stretch",
}));

export const ComparisonDetailsCardStyled = styled("div")(() => ({
  flex: 1,
  border: `1px solid ${colors.grey[300]}`,
  backgroundColor: "white",
  borderRadius: 4,
  display: "flex",
  flexDirection: "column",
}));

export const ComparisonSpaceBody = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  backgroundColor: theme.palette.workspace.bgLight,
  display: "flex",
  padding: "30px 0",
  borderRadius: "0 0 4px 4px",
}));

export const PickColumn = styled("div")(() => ({
  width: 30,
  marginRight: 5,
}));

export const GREY_25 = "#FCFCFD";
