import { useMsal } from "@azure/msal-react";
import { Stack, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { InteractionStatus } from "@azure/msal-browser";

export function UserInfo() {
  const { instance, inProgress } = useMsal();
  const account = instance.getAllAccounts()[0];

  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4689
  // Don't show anything during an interaction state -- user account info won't be ready
  if (inProgress !== InteractionStatus.None) {
    return null;
  }

  return (
    <>
      <Stack alignItems="center" direction="row">
        <AccountCircleIcon />
        <Typography pl={1} align={"left"} textAlign={"left"}>
          {account.name}
        </Typography>
      </Stack>
    </>
  );
}
