import { PageInfo } from "../graphql/generated/graphql";

/**
 * Interface type for a typical paginated query
 */
export interface IPagedQuery {
  edges: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  pageInfo: PageInfo;
}

/**
 * Merge function for relay style pagination pattern
 * Assumes that your incoming query will have the latest pageInfo and postpends the new edges
 * into the existing query result
 * See: https://www.apollographql.com/docs/react/pagination/core-api/#defining-a-field-policy
 * @param existing
 * @param incoming
 * @returns
 */
export function mergePageInfoQuery(
  existing: IPagedQuery = {
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
    },
    edges: [],
  },
  incoming: IPagedQuery
) {
  if (!existing || existing?.edges?.length === 0) {
    return incoming;
  }
  if (!incoming) {
    return existing;
  }
  return {
    ...existing,
    edges: [...existing.edges, ...incoming.edges],
    pageInfo: incoming.pageInfo,
  };
}
