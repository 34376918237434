import { toast } from "react-toastify";
import {
  DraftScenarioCreateMutation,
  DraftTypeEnum,
  useDraftScenarioCreateMutation,
} from "../../../graphql/generated/graphql";
import { CURRENT_DRAFT_YEAR } from "../../../utils/dates";
import { clientConfig } from "../../../config/config";

export const useStartDraft = () => {
  const [
    draftScenarioCreateMutation,
    { loading: createDraftLoading, error: createDraftError },
  ] = useDraftScenarioCreateMutation({});

  const startNewDraft = (
    type: DraftTypeEnum,
    onCompleted: (data: DraftScenarioCreateMutation) => void
  ) => {
    draftScenarioCreateMutation({
      variables: {
        request: {
          season: CURRENT_DRAFT_YEAR,
          draftType: type,
          clubId: clientConfig.clubId,
        },
      },
      onCompleted,
      onError: () => {
        toast.error(`Unable to start draft. Try again.`, {
          position: "top-center",
        });
      },
      refetchQueries: ["GetMyUndiscardedDraftsWithFilters"],
    });
  };
  return { startNewDraft, createDraftLoading, createDraftError };
};
