import { CURRENT_DRAFT_YEAR } from "../../utils/dates";

export type marvalMenu = {
  id: number;
  name: string;
  nav: string;
  disabled: boolean;
  subItems: [
    {
      id: number;
      name: string;
      nav: string;
      disabled: boolean;
    }
  ];
};

export const authenticatedMenu = [
  {
    id: 1,
    name: "Metrics",
    nav: "",
    disabled: false,
    subItems: [
      {
        id: 1.1,
        name: "Position",
        nav: "/metrics/position",
        disabled: false,
      },
      {
        id: 1.2,
        name: "Personnel",
        nav: "/metrics/personnel",
        disabled: false,
      },
    ],
  },
  {
    id: 2,
    name: "College",
    nav: "#",
    disabled: false,
    subItems: [
      {
        id: 2.1,
        name: "Scouting Reports",
        nav: "/evals?group=College",
        disabled: false,
      },
      {
        id: 2.2,
        name: "Best Available",
        nav: "/college/bestavailable",
        disabled: true,
      },
    ],
  },
  {
    id: 3,
    name: "Pro",
    nav: "#",
    disabled: false,
    subItems: [
      {
        id: 3.1,
        name: "Scouting Reports",
        nav: "/evals?group=Pro",
        disabled: false,
      },
      {
        id: 3.2,
        name: "Best Available",
        nav: "/college/bestavailable",
        disabled: true,
      },
    ],
  },
  {
    id: 4,
    name: "Roster",
    nav: "#",
    disabled: false,
    subItems: [
      {
        id: 4.1,
        name: "Roster Manager",
        nav: "/roster/manager",
        disabled: false,
      },
      {
        id: 4.2,
        name: "Roster Builder",
        nav: "/roster/builder",
        disabled: false,
      },
      {
        id: 4.3,
        name: "Depth Chart",
        nav: "",
        disabled: true,
      },
    ],
  },
  {
    id: 5,
    name: "Draft",
    nav: "#",
    disabled: false,
    subItems: [
      {
        id: 5.1,
        name: "Home",
        nav: "/home",
        disabled: false,
      },
      {
        id: 5.2,
        name: "Dashboard",
        nav: "/draft/dashboard",
        disabled: false,
      },
    ],
  },
  {
    id: 6,
    name: "Search",
    nav: "#",
    disabled: false,
    subItems: [
      {
        id: 6.1,
        name: "College",
        nav: "/player/search?group=college&draftYear=" + CURRENT_DRAFT_YEAR,
        disabled: false,
      },
      {
        id: 6.2,
        name: "Pro",
        nav: "/player/search?group=pro",
        disabled: false,
      },
    ],
  },
  {
    id: 7,
    name: "Admin",
    nav: "",
    disabled: false,
    subItems: [
      {
        id: 7.1,
        name: "Manage Roles",
        nav: "/admin/roles",
        disabled: false,
      },
      {
        id: 7.2,
        name: "Test Pages",
        nav: "/test/home",
        disabled: false,
      },
    ],
  },
];
