// Necessary if using App Router to ensure this file runs on the client
"use client";

import { datadogRum } from "@datadog/browser-rum";
import { clientConfig } from "../../config/config";
const validEnvs = [
    "dev-clubsumer"
    , "stg-clubsumer"
    , "prd-clubsumer"
];

if (validEnvs.includes(clientConfig.environment.name)) {
    console.log("DATADOG INIT: clientConfig.environment.name: " + clientConfig.environment.name)

    datadogRum.init({
        applicationId: '0fd49651-7b94-4cb3-9fa5-1f576ccde725',
        clientToken: 'pub1e84455a5ea64e7b142fe7c04a850693',
        site: 'us5.datadoghq.com',
        service: "marval-fe",
        env: clientConfig.environment.name,
        // version: '1.0.0', //TODO: get current Marval FE build
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        // Specify URLs to propagate trace headers for connection between RUM and backend trace
        allowedTracingUrls: [
            {
                match: process.env.MARVAL_API_URL ?? "http://localhost:3000",
                propagatorTypes: ["tracecontext"]
            },
        ],
    });
} else {
    console.log("Datadog RUM not enabled for " + clientConfig.environment.name);
}

export default function DatadogInit() {
    // Render nothing - this component is only included so that the init code
    // above will run client-side
    return null;
}
