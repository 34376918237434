import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Link from "next/link";
import {
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
} from "@mui/material";
import React from "react";
import { authenticatedMenu } from "./menus";

const MOBILE_DRAWER_WIDTH = 240;
const defaultLinkStates = authenticatedMenu.reduce((prev, _cur, idx) => {
  prev[idx] = false;
  return prev;
}, {} as Record<number, boolean>);

export const NavBarMobileDrawer = (props: {
  open: boolean;
  handleDrawerOpen: (value: boolean) => void;
}) => {
  const [mobileDrawerOpen, setDrawerMobileOpen] = React.useState(false);
  const [getCollapse, setCollapse] = React.useState(defaultLinkStates);

  const container =
    typeof window !== "undefined" ? window.document.body : undefined;

  const handleDrawerToggle = () => {
    setDrawerMobileOpen(!mobileDrawerOpen);
    props.handleDrawerOpen(!mobileDrawerOpen);
  };
  const handleCollapse = (index: number) => {
    setCollapse({ [index]: !getCollapse[index] });
  };

  return (
    <Drawer /* mobile drawer */
      container={container}
      variant="temporary"
      open={props.open}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
        disableEnforceFocus: true,
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: MOBILE_DRAWER_WIDTH,
        },
      }}
    >
      <Box sx={{ textAlign: "center", mt: 7 }}>
        <List>
          {authenticatedMenu.map((link, index) => (
            <React.Fragment key={`lib-${link.id}`}>
              <ListItemButton
                href="#"
                onClick={() => handleCollapse(index)}
                disabled={link.disabled}
              >
                <ListItemText key={`lit-${link.id}`} primary={link.name} />
                {link.subItems.length > 0 ? (
                  getCollapse[index] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItemButton>
              <Collapse in={getCollapse[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding key={`sublist-${link.id}`}>
                  {link.subItems?.map((si) => (
                    <ListItemButton
                      key={`sublib-${si.id}`}
                      sx={{ pl: 4 }}
                      disabled={si.disabled}
                      onClick={handleDrawerToggle}
                    >
                      <Link key={`sublink-${si.id}`} href={si.nav} legacyBehavior>
                        {si.name}
                      </Link>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
