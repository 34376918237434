import { createTheme } from "@mui/material/styles";
import { ClubInformation } from "./clubStaticData";

declare module "@mui/material/styles" {
  interface Palette {
    accent: {
      lightGreen: string;
      gold: string;
    };
    link: {
      main: string;
      hover: string;
      disabled: string;
    };
    textColor: {
      dark: string;
      light: string;
    };
    workspace: {
      bgLight: string;
      bgDark: string;
      hover: string;
      focus: string;
    };
  }
  interface PaletteOptions {
    accent: {
      lightGreen: string;
      gold: string;
    };
    link: {
      main: string;
      hover: string;
      disabled: string;
    };
    textColor: {
      dark: string;
      light: string;
    };
    workspace: {
      bgLight: string;
      bgDark: string;
      hover: string;
      focus: string;
    };
  }

  interface Theme {
    border: {
      primary: string;
    };
    borderRadius: {
      small: string;
      medium: string;
    };
    boxShadow: {
      medium: string;
      large: string;
    };
  }

  interface ThemeOptions {
    border: {
      primary: string;
    };
    borderRadius: {
      small: string;
      medium: string;
    };
    boxShadow: {
      medium: string;
      large: string;
    };
  }
}

const designColors = {
  grey: {
    25: "#FCFCFD",
    50: "#F2F4F8",
    100: "#DDE1E6",
    200: "#C1C7CD",
    300: "#A2A9B0",
    400: "#878D96",
    500: "#697077",
    600: "#4D5358",
    700: "#343A3F",
    800: "#21272A",
    900: "#121619",
  },
  success: {
    // success
    light: "#F0FDF9",
    main: "#CCFBEF",
    dark: "#0E9384",
  },
  warning: {
    // orange
    light: "#FDEAD7",
    main: "#EAAA08",
    dark: "#932F19",
  },
  error: {
    // error
    light: "#FEF3F2",
    main: "#F04438",
    dark: "#912018",
  },
  info: {
    // moss
    light: "#E6F4D7",
    main: "#CEEAB0",
    dark: "#669F2A",
  },
  accent: {
    lightGreen: "#D1E4D6",
    gold: "#DFA100",
  },
  link: {
    main: "#3B4BDB",
    hover: "#232F9D",
    disabled: "#7681e6",
  },
  textColor: {
    dark: "#121619",
    light: "#343A3F",
  },
};

export const setThemeForUser = (t: ClubInformation) => {
  return createTheme({
    typography: {
      fontFamily: ["Open Sans", "massilia-variable"].join(","),
    },
    palette: {
      ...designColors,
      primary: {
        main: t.primary.main,
        light: t.primary.light,
        dark: t.primary.dark,
      },
      secondary: {
        main: t.secondary,
      },
      workspace: {
        bgLight: t.workspace.bgLight,
        bgDark: t.workspace.bgDark,
        hover: t.workspace.hover,
        focus: t.workspace.focus,
      },
    },
    border: {
      primary: `1px solid ${designColors.grey[200]}`,
    },
    borderRadius: {
      small: "4px",
      medium: "8px",
    },
    boxShadow: {
      medium: "0px 1px 3px 0px #1018281A",
      large: "0px 12px 16px -4px #10182814",
    },
  });
};
